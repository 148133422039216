import React from "react";
import { Container, Row, Col, Card, Carousel, Button } from "react-bootstrap";

const NewsCarousel = ({ news, handleMenuShow }) => {
  return (
    <Container fluid>
      <h3 className="mb-4">Latest news</h3>
      <Carousel interval={7000} controls={true}>
        {news.map((newsItem, index) => (
          <Carousel.Item key={index}>
            <Container fluid>
              <Row className="justify-content-center">
                <Col md={10}>
                  <Card onClick={() => {handleMenuShow(newsItem)}} className="p-3 text-white news-carousel">
                    <Row className="align-items-center h-100">
                      <Col md={8}>
                        <Card.Body className="d-flex flex-column justify-content-center h-100">
                          <Card.Title className="fw-bold">
                            <Button onClick={() => {handleMenuShow(newsItem)}} variant="light">{newsItem.title}</Button>
                          </Card.Title>
                          <Card.Text>{newsItem.description}</Card.Text>
                          <Card.Text>
                            <strong>Source</strong>: {newsItem.source}
                          </Card.Text>
                        </Card.Body>
                      </Col>
                      <Col className="news-img" md={4}>
                        <Card.Img
                          variant="top"
                          src={newsItem.img_src}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
};

export default NewsCarousel;